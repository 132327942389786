import Head from 'next/head'
import React from 'react'
import { MissingFileSvg } from '~common/svgs'
import { ErrorPageBlock } from '~components/ui/molecules/error-page-block'
export const NotFoundPage: React.FC = () => (
  <>
    <Head>
      <title>Not Found | Pharmaspectra</title>
    </Head>
    <ErrorPageBlock
      errorMessage="Either the page doesn’t exist or you do not have access to it"
      icon={<MissingFileSvg />}
    />
  </>
)
